import styles from './LoginContent.module.scss'
import type { LoginLayoutProps } from './LoginContent.types'

export function LoginContent({
  title,
  subtitle,
  children,
  footer,
  isInfo
}: LoginLayoutProps) {
  return (
    <div
      className={`
        ${styles.loginComponentWrapper}
        ${isInfo ? styles.isInfo : ''}
      `}
    >
      <h4 data-testid="login-title">{title}</h4>
      <small data-testid="login-subtitle">{subtitle}</small>
      {children}
      {footer}
    </div>
  )
}
