import { PropsWithChildren, useEffect } from 'react'
import { trackLastUsedApp } from '../utils'

export function TrackLastApp(props: PropsWithChildren<{ appName: string }>) {
  const { children, appName } = props

  useEffect(
    function () {
      trackLastUsedApp(appName, localStorage)
    },
    [appName]
  )

  return children
}
