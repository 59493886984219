import './instrument.ts'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'
import { notification } from 'antd'
import './i18n/config.ts'

import App from './App.tsx'
import { queryClient } from '@utils/queries'
import { AuthenticationProvider } from '@authentication/context'
import { ToastProvider, ThemeProvider } from 'mysaiva-web-components'
import Zendesk from 'react-zendesk'
import ErrorPage from './views/ErrorPage/ErrorPage'
import * as Sentry from '@sentry/react'

const zendeskToken = import.meta.env.VITE_ZENDESK_TOKEN

const setting = {
  color: {
    theme: '#000'
  },
  launcher: {
    chatLabel: {
      'en-US': 'Need Help'
    }
  },
  contactForm: {
    fields: [
      { id: 'description', prefill: { '*': 'My pre-filled description' } }
    ]
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <ThemeProvider>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <AuthenticationProvider>
              <ToastProvider toast={notification}>
                {zendeskToken && (
                  <Zendesk
                    defer
                    zendeskKey={zendeskToken}
                    {...setting}
                    onError={
                      (error: unknown) => {
                        console.error('Zendesk error:', error)
                      }
                    }
                  />
                )}
                <App />
              </ToastProvider>
            </AuthenticationProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)
