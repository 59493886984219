import { useState } from 'react'
import styles from './Shared.module.scss'
import { LoginContent } from '../components'
import {
  Button,
  Form,
  FormItem,
  Icon,
  OTPInput,
  useForm
} from 'mysaiva-web-components'
import LoadingIcon from '../../../assets/pill-loader.gif'
import { useTranslation } from 'react-i18next'
import { useLogin } from '../mutations'
import MobileAppPromotion from './MobileAppPromotion'
import { HelpLink } from './HelpLink'
import { LoginTokens, OtpLogin, FrontendPlatform } from '../../../types/api'
import { Flex } from 'antd'

type LoginStepOTPProps = {
  loading?: boolean
  emailStepSuccessArgs: OtpLogin & { phrase: string }
  onLoginSuccess: (data: LoginTokens, email: OtpLogin) => void
  onBackToEmailButtonClick: () => void
  onHandleResendOTP: () => void
}

export function LoginStepOTP(args: LoginStepOTPProps) {
  const {
    loading,
    emailStepSuccessArgs,
    onLoginSuccess,
    onBackToEmailButtonClick,
    onHandleResendOTP
  } = args
  const form = useForm<{ otp: string }>()
  const { t } = useTranslation()
  const [info, setInfo] = useState<string | null>(null)

  function handleResendOTP() {
    onHandleResendOTP()
    setInfo(t('login.loginStepOTP.otpResentInfo'))
    form.resetFields()
  }

  function handleOTPChange(otp: string) {
    if (isLoginPending || loading) return
    if (otp?.length != 6) return
    if (!emailStepSuccessArgs) return

    login({
      ...emailStepSuccessArgs,
      password: otp,
      platform: FrontendPlatform.Web
    })
  }

  function submitOTP({ otp }: { otp: string }) {
    handleOTPChange(otp)
  }

  const { mutate: login, isPending: isLoginPending } = useLogin({
    onSuccess(data, args) {
      onLoginSuccess(data, { email: args.email })
    },
    onError() {
      form.resetFields()
    }
  })

  return (
    <div style={{ position: 'relative' }}>
      {info && (
        <div className={styles.info}>
          <Icon icon={'InfoCircle'} color="#fff" />
          {info}
        </div>
      )}
      <LoginContent
        title={t('login.loginStepOTP.title')}
        subtitle={t('login.loginStepOTP.subtitle', {
          email: emailStepSuccessArgs?.email
        })}
        footer={<HelpLink />}
        isInfo={info !== null}
      >
        {loading ||
          (isLoginPending && (
            <div className={styles.loadingBox}>
              <img src={LoadingIcon} width={52} />
              <div>Verifying...</div>
            </div>
          ))}
        <div className={styles.loginRow}>
          {t('login.loginStepOTP.loginRow')}
          <br />
          <h3 data-testid="login-phrase">{emailStepSuccessArgs?.phrase}</h3>
        </div>
        <Form
          layout="vertical"
          onFinish={submitOTP}
          data-testid="login-otp-form"
          initialValues={{ otp: '' }}
          form={form}
        >
          <FormItem name="otp">
            <OTPInput onChange={handleOTPChange} />
          </FormItem>
        </Form>
        <Flex>
          <Button
            type="text"
            onClick={onBackToEmailButtonClick}
            data-testid="login-back-btn"
          >
            {t('login.loginStepEmail.backToEmail')}
          </Button>
          <Button
            type="text"
            onClick={handleResendOTP}
            data-testid="login-resend-otp-btn"
          >
            {t('login.loginStepEmail.resendOTP')}
          </Button>
        </Flex>
        <MobileAppPromotion message={t('login.loginStepOTP.appMessage')} />
      </LoginContent>
    </div>
  )
}
