import { useState } from 'react'
import type { LoginTokens, OtpLogin, OtpPhrase } from 'types/api'
import { apiClient } from '@services/api'
import { useRequestOTP } from '../../mutations'
import {
  AuthLayout,
  LoginStepEmail,
  LoginStepOTP,
  LoginStepStatus
} from '../../components'
import { useAuthentication } from '@authentication/context'

enum Step {
  STEP_EMAIL,
  STEP_STATUS,
  STEP_OTP
}

export function Login() {
  const [step, setStep] = useState<Step>(Step.STEP_EMAIL)
  const [emailStepSuccessArgs, setEmailStepSuccessArgs] = useState<
    OtpPhrase & OtpLogin
  >()
  const { login } = useAuthentication()

  function handleEmailForm(email: OtpLogin) {
    requestOTP(email)
  }

  const { mutate: requestOTP, isPending: isOTPPending } = useRequestOTP(
    { apiClient },
    {
      onSuccess: function nextStep(data: OtpPhrase, args: OtpLogin) {
        setEmailStepSuccessArgs({
          email: args.email,
          phrase: data.phrase,
          user_id: data.user_id
        })
        if (data.active_devices) {
          setStep(Step.STEP_STATUS)
        } else {
          setStep(Step.STEP_OTP)
        }
      }
    }
  )

  function handleFialedStatusRequest() {
    setStep(Step.STEP_EMAIL)
  }

  function handleMoveToOTP() {
    if (emailStepSuccessArgs) {
      requestOTP({ email: emailStepSuccessArgs.email, email_login: true })
      setStep(Step.STEP_OTP)
    }
  }

  async function saveTokensAndLogin(
    tokens: LoginTokens,
    params: OtpLogin
  ): Promise<void> {
    login(params.email, tokens)
  }

  return (
    <AuthLayout>
      <>
        {step == Step.STEP_EMAIL && (
          <LoginStepEmail
            loading={isOTPPending}
            onSuccess={handleEmailForm}
            emailAndPhrase={emailStepSuccessArgs}
          />
        )}
        {emailStepSuccessArgs && step == Step.STEP_STATUS && (
          <LoginStepStatus
            emailStepSuccessArgs={emailStepSuccessArgs}
            onMoveToOTP={handleMoveToOTP}
            onFailedRequest={handleFialedStatusRequest}
            onLoginSuccess={(tokens) =>
              saveTokensAndLogin(tokens, emailStepSuccessArgs)
            }
          />
        )}
        {emailStepSuccessArgs && step == Step.STEP_OTP && (
          <LoginStepOTP
            emailStepSuccessArgs={emailStepSuccessArgs}
            onLoginSuccess={saveTokensAndLogin}
            onBackToEmailButtonClick={handleFialedStatusRequest}
            onHandleResendOTP={handleMoveToOTP}
          />
        )}
      </>
    </AuthLayout>
  )
}

export default Login
