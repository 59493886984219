import {
  init,
  makeFetchTransport,
  moduleMetadataIntegration,
  makeMultiplexedTransport
} from '@sentry/browser'

const EXTRA_KEY = 'ROUTE_TO'

const coreSentryDns = import.meta.env.VITE_CORE_SENTRY_DSN

const transport = makeMultiplexedTransport(makeFetchTransport, (args) => {
  const event = args.getEvent()
  if (
    event &&
    event.extra &&
    EXTRA_KEY in event.extra &&
    Array.isArray(event.extra[EXTRA_KEY])
  ) {
    return event.extra[EXTRA_KEY]
  }
  return []
})

init({
  dsn: coreSentryDns,
  integrations: [moduleMetadataIntegration()],
  transport,
  beforeSend: (event) => {
    if (event?.exception?.values?.[0]?.stacktrace?.frames) {
      const frames = event.exception.values[0].stacktrace.frames
      // Find the last frame with module metadata containing a DSN
      const routeTo = frames
        .filter((frame) => frame.module_metadata && frame.module_metadata.dsn)
        .map((v) => v.module_metadata)
        .slice(-1) // using top frame only - you may want to customize this according to your needs

      if (routeTo.length) {
        event.extra = {
          ...event.extra,
          [EXTRA_KEY]: routeTo
        }
      }
    }

    return event
  }
})
