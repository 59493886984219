import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import styles from './Shared.module.scss'
import { LoginContent } from './LoginContent'
import MobileAppPromotion from './MobileAppPromotion'
import { useTranslation } from 'react-i18next'

import { Button, Form, FormItem, Input } from 'mysaiva-web-components'
import { AuthSearchParams } from '../types'
import { HelpLink } from './HelpLink'
import { OtpLogin } from '../../../types/api'

type LoginStepEmailProps = {
  emailAndPhrase?: OtpLogin
  onSuccess: (email: OtpLogin) => void
  loading?: boolean
}

export function LoginStepEmail(args: LoginStepEmailProps) {
  const { emailAndPhrase, onSuccess, loading } = args
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()

  function submitEmail(values: { email: string }) {
    onSuccess({ email: values.email })
  }

  useEffect(
    function automaticallyRequestOTP() {
      const email = searchParams.get(AuthSearchParams.Email)
      const autoLogin = searchParams.has(AuthSearchParams.ShouldAutoLogin)
      if (!autoLogin || !email) return

      setSearchParams({ email })
      submitEmail({ email })
    },
    // we really want to run this only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  )

  return (
    <LoginContent
      title={t('login.loginStepEmail.title')}
      subtitle=""
      footer={<HelpLink />}
    >
      <Form
        layout="vertical"
        onFinish={submitEmail}
        data-testid="login-email-form"
        initialValues={{ email: emailAndPhrase?.email }}
      >
        <FormItem
          style={{ paddingTop: '20px' }}
          name="email"
          label={t('login.loginStepEmail.emailLabel')}
          rules={[
            {
              required: true,
              message: t('login.loginStepEmail.emailRequired')
            },
            {
              type: 'email',
              message: t('login.loginStepEmail.emailInvalid')
            }
          ]}
        >
          <Input
            className={styles.loginInput}
            shadow={true}
            placeholder={t('login.loginStepEmail.emailLabel')}
            type="email"
            trim
          />
        </FormItem>
        <FormItem>
          <Button
            loading={loading}
            disabled={false}
            className={styles.loginButton}
            type="primary"
            htmlType="submit"
            data-testid="login-email-btn"
          >
            {t('login.loginStepEmail.button')}
          </Button>
        </FormItem>
      </Form>
      <MobileAppPromotion message={t('login.loginStepEmail.appMessage')} />
    </LoginContent>
  )
}
