import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { jwtDecode } from 'jwt-decode'
import { Tooltip } from 'antd'

import animation from '../../../../assets/invitation_animation.gif'
import { useInvitation } from '../../mutations'
import styles from './Invitation.module.scss'
import { LoginContent, AuthLayout } from '../../components'
import LinkText from '../../../../utils/LinkText'
import {
  Button,
  Form,
  FormItem,
  Checkbox,
  useForm,
  useWatch
} from 'mysaiva-web-components'
import {
  AuthSearchParams,
  InviteJwtTokenPayload,
  UserCapability
} from '../../types'
import { apiClient } from '@services/api'

enum Step {
  STEP_INVITE,
  STEP_LOGIN,
  STEP_SUCCESS
}

export function Invitation() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const form = useForm()
  const [step, setStep] = useState<Step>(Step.STEP_INVITE)
  const [submittable, setSubmittable] = useState<boolean>(false)
  const values = useWatch([], form)
  const { token } = useParams()
  const [email, setEmail] = useState<string>()
  const [capabilities, setCapabilities] = useState<UserCapability[]>()

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode<InviteJwtTokenPayload>(token)
      if (decodedToken.email) {
        setEmail(decodedToken.email)
        setCapabilities(decodedToken.capabilities)
      }
    }
  }, [token])

  useEffect(() => {
    if (values && values.acceptTerms) {
      setSubmittable(true)
    } else {
      setSubmittable(false)
    }
  }, [form, values])

  const { mutate: acceptInvitation } = useInvitation(
    { apiClient },
    {
      onSuccess: function goToSuccessStep() {
        if (capabilities?.includes(UserCapability.WEB_APP_LOGIN)) {
          setStep(Step.STEP_LOGIN)
        } else {
          setStep(Step.STEP_SUCCESS)
        }
      }
    }
  )

  function acceptInvite() {
    if (token) {
      acceptInvitation({ payload: { token } })
    }
  }

  const invitationStepInvite = (
    <LoginContent
      title={t('invitation.invitationStepInvite.title')}
      subtitle={t('invitation.invitationStepInvite.subtitle')}
      footer={
        <div>
          <small>{t('invitation.invitationStepInvite.footer')}</small>
          <Button
            type="link"
            onClick={() => navigate('/login')}
            data-testid="invite-login-btn"
          >
            {t('invitation.invitationStepInvite.loginButton')}
          </Button>
        </div>
      }
    >
      <div className={styles.wrapper}>
        <small>{t('invitation.invitationStepInvite.emailLabel')}</small>
        <Tooltip arrow={false} title={email}>
          <div className={styles.email}>{email}</div>
        </Tooltip>
      </div>
      <Form
        form={form}
        name="invitation"
        onFinish={acceptInvite}
        data-testid="invite-form"
      >
        <FormItem
          name="acceptTerms"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: t('invitation.invitationStepInvite.checkboxError')
            }
          ]}
        >
          <Checkbox className={styles.checkbox}>
            <small>
              <Trans
                i18nKey="invitation.invitationStepInvite.checkbox"
                components={{
                  link1: (
                    <LinkText
                      to="https://saiva.ai/terms-conditions/"
                      title={t('common.termsAndConditions')}
                    />
                  ),
                  link2: (
                    <LinkText
                      to="https://saiva.ai/privacy-policy/"
                      title={t('common.privacyPolicy')}
                    />
                  )
                }}
              />
            </small>
          </Checkbox>
        </FormItem>
        <FormItem>
          <Button
            disabled={!submittable}
            className={styles.invitationButton}
            htmlType="submit"
            data-testid="invite-join-btn"
          >
            {t('invitation.invitationStepInvite.button')}
          </Button>
        </FormItem>
      </Form>
    </LoginContent>
  )

  function login() {
    if (!email) return
    const params = new URLSearchParams()
    params.set(AuthSearchParams.Email, email)
    params.set(AuthSearchParams.ShouldAutoLogin, '')
    navigate(`/login/?${params.toString()}`)
  }

  const invitationStepLogin = (
    <LoginContent
      title={t('invitation.invitationStepLogin.title')}
      subtitle={t('invitation.invitationStepLogin.subtitle')}
    >
      <div style={{ padding: '20px 0px' }}>
        <Button
          className={styles.invitationButton}
          onClick={login}
          data-testid="invite-login-btn"
        >
          {t('invitation.invitationStepLogin.button')}
        </Button>
      </div>
    </LoginContent>
  )

  const invitationStepSuccess = (
    <LoginContent
      title={t('invitation.invitationStepSuccess.title')}
      subtitle={t('invitation.invitationStepSuccess.subtitle')}
    >
      <img src={animation} alt="Invitation animation" width={'100%'} />
    </LoginContent>
  )

  return (
    <AuthLayout>
      <>
        {step == Step.STEP_INVITE && invitationStepInvite}
        {step == Step.STEP_LOGIN && invitationStepLogin}
        {step == Step.STEP_SUCCESS && invitationStepSuccess}
      </>
    </AuthLayout>
  )
}

export default Invitation
