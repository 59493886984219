import LoadingIcon from '../../assets/pill-loader.gif'
import styles from './LoadingPage.module.scss'

function LoadingPage() {
  return (
    <div className={styles.loading} data-testid="loading-overlay">
      <div style={{ width: '100%' }}>
        <img src={LoadingIcon} width={'50px'}></img>
      </div>
    </div>
  )
}

export default LoadingPage
