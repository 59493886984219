import { ReactNode } from 'react'
import { AuthenticationStatus } from '../types'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import LoadingPage from '../../../views/LoadingPage/LoadingPage'

type ProtectedRouteProps = {
  loginState: AuthenticationStatus
  children?: ReactNode
}

export function ProtectedRoute({ loginState, children }: ProtectedRouteProps) {
  const { pathname, search } = useLocation()

  if (loginState === AuthenticationStatus.Loading) {
    return <LoadingPage />
  }

  if (loginState === AuthenticationStatus.LoggedOut) {
    const redirect = `${pathname}${search}`
    return <Navigate to={`/login/?redirect=${redirect}`} replace />
  }

  return children ? children : <Outlet />
}
