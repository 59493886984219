import AppleButton from '../../../assets/app_store.svg?react'
import AndroidButton from '../../../assets/play_store.svg?react'
import styles from './Shared.module.scss'

const appleLink = 'https://apps.apple.com/us/app/mysaiva/id1621426257'
const androidLink =
  'https://play.google.com/store/apps/details?id=com.saivahc.saivaai'

export default function MobileAppPromotion({ message }: { message: string }) {
  return (
    <div>
      <div className={styles.mobileMessage}>{message}</div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignContent: 'space-between'
        }}
      >
        <a href={appleLink} style={{ width: '100%' }}>
          <AppleButton style={{ height: '35px', width: '100%' }} />
        </a>
        <a href={androidLink} style={{ width: '100%' }}>
          <AndroidButton style={{ height: '35px', width: '100%' }} />
        </a>
      </div>
    </div>
  )
}
