import { ReactElement } from 'react'
import styles from './AuthLayout.module.scss'
import { Flex } from 'antd'
import { FooterContent } from 'mysaiva-web-components'
import logo from '../../../assets/saiva-logo-combined.png'

type AuthLayoutProps = {
  children: ReactElement
}

export function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <div className={styles.loginContainer}>
      <img className={styles.logo} src={logo} />
      <div className={styles.loginWrapper}>{children}</div>
      <Flex className={styles.footer}>
        <FooterContent />
      </Flex>
    </div>
  )
}
