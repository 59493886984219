type LinkTextProps = {
  to?: string
  title?: string
}

function LinkText(props: LinkTextProps) {
  return (
    <a href={props.to || '#'} target="_blank" title={props.title || ''}>
      {props.title}
    </a>
  )
}

export default LinkText
