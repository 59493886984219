import './App.scss'
import { Suspense, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Login, Invitation } from '@authentication/views'
import { useAuthentication } from '@authentication/context'
import {
  ProtectedRoute,
  SelectApp,
  TrackLastApp
} from '@authentication/components'
import LoadingPage from './views/LoadingPage/LoadingPage'
import ErrorPage from './views/ErrorPage/ErrorPage'
import { SaivaProduct } from './types/api'

const WoundsApp = lazy(() => import('mysaiva-wounds/app'))
const UptApp = lazy(() => import('mysaiva-upt/app'))
const TherapyApp = lazy(() => import('mysaiva-therapy/app'))

function App() {
  const { status, user } = useAuthentication()

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login/:email" element={<Login />} />
        <Route path="/join/:token" element={<Invitation />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route element={<ProtectedRoute loginState={status} />}>
          <Route path="*" element={<ErrorPage />} />
          {user &&
            Object.entries(SaivaProduct).map(([key, product]) =>
              user.product_list.includes(product) ? (
                <Route
                  key={key}
                  path={mapProductUrl(product)}
                  element={
                    <TrackLastApp appName={product}>
                      {getAppComponent(product)}
                    </TrackLastApp>
                  }
                />
              ) : null
            )}
        </Route>
        <Route path="/" element={<SelectApp />} />
        <Route path="/dashboard" element={<Navigate to="/upt/dashboard" />} />
        <Route path="/users" element={<Navigate to="/upt/users" />} />
        <Route path="/facilities" element={<Navigate to="/upt/facilities" />} />
        <Route path="/reports" element={<Navigate to="/upt/reports" />} />
        <Route
          path="/integrations"
          element={<Navigate to="/upt/integrations" />}
        />
        <Route path="/settings" element={<Navigate to="/upt/settings" />} />
        <Route
          path="/resident/:residentToken"
          element={<Navigate to={`/upt${window.location.pathname}`} />}
        />
      </Routes>
    </Suspense>
  )
}

export default App

// Helper function to get the app component dynamically
const getAppComponent = (product: SaivaProduct) => {
  switch (product) {
    case SaivaProduct.Upt:
      return <UptApp />
    case SaivaProduct.WoundCare:
      return <WoundsApp />
    case SaivaProduct.Therapy:
      return <TherapyApp />
    default:
      return <ErrorPage /> // Default case if the product is not recognized
  }
}

function mapProductUrl(product: SaivaProduct) {
  switch (product) {
    case SaivaProduct.Upt:
      return '/upt/*'
    case SaivaProduct.WoundCare:
      return '/wounds/*'
    case SaivaProduct.Therapy:
      return '/therapy/*'
  }
}
