import { useTranslation } from 'react-i18next'
import styles from './HelpLink.module.scss'
import { Body2 } from 'mysaiva-web-components'

export function HelpLink() {
  const { t } = useTranslation()

  return (
    <a
      className={styles.link}
      data-testid="login-help-link"
      href="https://support.saiva.ai/hc/en-us/articles/13942708186139-Having-trouble-logging-in"
      target="_blank"
    >
      <Body2 tag="span">{t('login.loginStepEmail.footer')}</Body2>
    </a>
  )
}
